<template>
  <div class="performance-area bottom-image-text ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-6 align-items-start section-title">
          <h2
            class="title text-uppercase text-center"
            aria-hidden="true"
            role="document"
          >
            {{ $t("news.title") }}
          </h2>
        </div>
      </div>

      <div class="single-security-wrap owl-carousel owl-theme">
        <carousel
          ref="my-carousel"
          :autplay="true"
          :loop="true"
          :paginationEnabled="true"
          :autoplaySpeed="true"
          :autoplayTimeout="500"
          :navigationEnabled="true"
          navigationNextLabel=""
          navigationPrevLabel=""
          :perPage="showPageNumber"
        >
          <slide data-index="0" v-for="(item, index) in newsList" :key="index">
            <div
              class="single-blog-post"
              @click="showNewsModel(item.announcement_message_id)"
            >
              <div class="event-border">
                <p class="carousel-date-style">
                  {{ item.post_date }}
                </p>
                <p class="news-title">
                  {{ item.message_title }}
                </p>
              </div>
            </div>
          </slide>
        </carousel>
      </div>

      <NewsModel v-if="waitOnCallModel" :NewsModelList="NewsModelList" />
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";
import NewsModel from "components/DefaultHome/KECC5G/Common/NewsModal";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "HomeTextCarousel",
  components: {
    Carousel,
    Slide,
    NewsModel,
  },
  data() {
    return {
      newsList: null,
      showPageNumber: window.innerWidth >= 991 ? 4 : 1,
      NewsModelList: [],
      waitOnCallModel: false,
      apiUrl: `${process.env.VUE_APP_API_URL}/announcement/`,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("resize", () => {
      that.showPageNumber = window.innerWidth >= 991 ? 4 : 1;
    });
    let api = `${this.apiUrl}message_list?announcement_id=5&language_id=${this.$i18n.locale}&auto_replace=false&announcement_group_id=3&limit=12&offset=0`;
    this.axios.get(api).then((response) => {
      for (let index in response.data["list"]) {
        response.data["list"][index].post_date = DateFormat(
          new Date(
            Date.parse(
              response.data["list"][index].post_date.replace(/-/g, "/")
            )
          ),
          "yyyy-mm-dd"
        );
      }
      this.newsList = response.data["list"];
    });
  },
  methods: {
    async showNewsModel(message_id) {
      // let apiUrl = `${this.apiUrl}message?announcement_message_id=${message_id}&language_id=${this.$i18n.locale}&language_format=value`;
      // await this.axios.get(apiUrl).then((response) => {
      //   this.NewsModelList = response.data;
      //   this.waitOnCallModel = true;
      // });
      // this.$root.$emit("bv::show::modal", "modal-news");
      this.$router.push({ path: "/news/post/"+message_id }); 
    },
  },
};
</script>
<style scoped>
.single-security-wrap {
  margin-top: 50px;
}
</style>
<style>
.VueCarousel-navigation-button {
  position: absolute;
  top: 50%;
  box-sizing: border-box;
  color: #000;
  text-decoration: none;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
}

.VueCarousel-navigation-next::after {
  content: url(https://www.javitscenter.com/images/b_r_arrow.png);
}

.VueCarousel-navigation-prev::after {
  content: url(https://www.javitscenter.com/images/b_l_arrow.png);
  left: 6px;
}

.VueCarousel-pagination {
  display: none;
}

.performance-area .container {
  max-width: 1400px;
}

.b-avatar-sm {
  width: 1rem;
  height: 1rem;
}
.btn-warning {
  color: #212529;
  background-color: #ffff00;
  border-color: #cccc00;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.bottom-image {
  padding-top: 5vh;
  background-image: none;
  background-repeat: repeat;
  background-image: url("~assets/project_images/news/new-background.png");
  background-size: auto;
}

.news-title-color {
  font-size: 34px !important;
  font-weight: bold !important;
  color: #000033;
}

.news-more-style {
  color: #007fff;
}

.background-image1 {
  background-image: url("~assets/project_images/news/news_20210607_01.jpg");
}

.background-image2 {
  background-image: url("~assets/project_images/news/news_20210514_01.png");
}

.background-image3 {
  background-image: url("~assets/project_images/news/news_20210430_01.png");
}

.background-image4 {
  background-image: url("~assets/project_images/news/news_20210505_04.jpg");
}

.background-image5 {
  background-image: url("~assets/project_images/news/news_20210505_01.png");
}

.background-image6 {
  background-image: url("~assets/project_images/news/news_20210505_02.png");
}

.background-image7 {
  background-image: url("~assets/project_images/news/news_20210505_03.png");
}

.background-image8 {
  background-image: url("~assets/project_images/news/news_20210505_04.jpg");
}

.news-container {
  position: relative;
}

.news-images {
  display: block;
  width: auto;
  height: auto;
  min-height: 20vh !important;
  max-height: 20vh !important;
}

.news-images-mask {
  width: auto;
  height: auto;
  min-height: 35vh;
  max-height: 25vh;
  background-color: rgba(0, 0, 0, 1);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
  z-index: 9;
}

.news-image {
  display: block;
  width: auto;
  height: auto;
  min-height: 25vh;
  max-height: 25vh;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  transition: 0.5s ease;
}

.news-container:hover .overlay {
  opacity: 1;
  background-color: #000033;
  height: 100%;
}

.hover-title {
  font-size: 15px;
  color: white;
  padding-bottom: 5px;
}

.hover-content {
  color: white;
  position: absolute;
  bottom: 10px;
}

.hover-date {
  font-size: 22px;
  font-weight: bold;
}

.hoverText {
  padding: 30px 5px 15px 5px;
  width: 90%;
  height: 100%;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
}
.hoverText hr {
  border: 1px solid #cccc00;
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
}
.performance-area
  .single-security-wrap.owl-theme
  .VueCarousel-dot-container
  .VueCarousel-dot.VueCarousel-dot--active {
  background-image: linear-gradient(to bottom right, #007fff, #007fff);
  width: 20px !important;
  padding: 0 1rem;
  
}

.single-blog-post {
  padding-right: 10px;
  cursor: pointer;
}
.news-title {
  font-size: 16px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
}


.news-title a {
    color: #00cccc;
    transition: all 0.1s;
}


.news-title a:hover {
    color: #00cccc;
    transition: all 0.1s;
}


.btn-secondary {
  background-color: #d1d1d1;
}

.b-avatar {
  margin-right: 10px;
}

.performance-area::before {
  background-color: transparent;
}

.b-avatar {
  margin-top: 1% !important;
}

.VueCarousel {
  padding-left: 5px;
  
}

.VueCarousel-navigation {
    margin-top: -20px;
}


.VueCarousel-navigation-button.VueCarousel-navigation-prev {
  padding: 0px !important;
  margin-left: -20px !important;
}

.VueCarousel-navigation-button.VueCarousel-navigation-next {
  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: -20px !important;
}

.performance-area .container {
  max-width: 1400px;
}

.bottom-image-text {
  padding-top: 5vh;
  /*background-color: #eff4f5 !important;*/
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: top;
}

.performance-area
  .single-security-wrap.owl-theme
  .VueCarousel-dot-container
  .VueCarousel-dot.VueCarousel-dot--active {
  background-image: linear-gradient(to bottom right, #007fff, #007fff);
  width: 20px !important;
}

.event-border {
  border-left: 2px solid #00CCCC;
  padding: 0 30px 0 30px !important;
  box-sizing: border-box;
  color: #000066;
}
.VueCarousel-slide:first-child .event-border {
  border-left: none;
}
.carousel-date-style {
  color: #00CCCC;
  margin-bottom: 0;
}
.text-uppercase {
  color: #000033;
  font-weight: bold;
}

@media (max-width: 991px)    {


      .event-border {
        border-left: 0px solid #00CCCC;
    }

    .VueCarousel-navigation-button.VueCarousel-navigation-prev {
        margin-left: 30px !important;
    }

    .VueCarousel-navigation-button.VueCarousel-navigation-next {
        margin-right: 30px !important;
    }

    .event-border {
    padding: 0 10% 0 10% !important;
}




    }
</style>
